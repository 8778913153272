











































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readProjects } from '@/store/main/getters';
import { dispatchGetProjects, dispatchDeleteProject } from "@/store/main/actions";


@Component
export default class Projects extends Vue {
  public id = '';
  public name = '';
  public dialog = false;
  public search = '';
  public filterProjects = [] as any;
  public headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Client',
      sortable: true,
      value: 'client',
      align: 'left',
    },
    {
      text: 'Creator',
      sortable: true,
      value: 'creator_name',
      align: 'left',
    },
    {
      text: 'Status',
      sortable: false,
      value: 'mode',
      align: 'left',
    },
    {
      text: 'Location',
      sortable: true,
      value: 'location',
      align: 'left',
    },
    {
      text: 'State',
      sortable: true,
      value: 'state',
      align: 'left',
    },
    {
      text: 'Actions',
      value: 'id',
      align: 'center',
      sortable: false,
    },
  ];

  public pagination = {
    ascending: true,
    sortBy: 'name',
    rowsPerPage: 25,
    page: 1,
  };

  public async mounted() {
    await dispatchGetProjects(this.$store);
    this.filterProjects = [...readProjects(this.$store)];
  }

  @Watch('search')
  public searchName(searchValue) {
    if (searchValue) {
      this.filterProjects = this.filterProjects.filter((e) => {
        return e.name.toLowerCase().includes(searchValue.toLowerCase());
      });
    }else {
      this.filterProjects = [...readProjects(this.$store)];
    }
  }

  public async confirm() {
    this.dialog = false;
    await dispatchDeleteProject(this.$store, Number(this.id));
    await dispatchGetProjects(this.$store);
    this.filterProjects = [...readProjects(this.$store)];
  }
}
